import React from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import * as Yup from 'yup';
import CurrencyField from '../../components/CurrencyField'
import PopoverField from '../../components/PopoverField';
import FormStepContainer from "../FormStepContaner";

const IncomeStep = (props) => {
    const { values } = useFormikContext();
    const isCouple = values.planType === 'couple';

    return (

        <FormStepContainer
            primary={<>
                <Grid item xs={12}>
                    <PopoverField
                        label="annualSalaryPrimary"
                        popoverContent="Enter your total annual salary before taxes and deductions. Include your base salary and any guaranteed bonuses."
                    >
                        <CurrencyField
                            fullWidth
                            component={TextField}
                            name="annualSalaryPrimary"
                            label="Annual Gross Salary"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="hasOtherIncomePrimary"
                        popoverContent="Select if you have additional sources of income beyond your salary."
                    >
                        <FormControlLabel
                            control={
                                <Field
                                    component={Switch}
                                    name="hasOtherIncomePrimary"
                                    type="checkbox"
                                />
                            }
                            label="I have other sources of income"
                        />
                    </PopoverField>
                </Grid>

                {values.hasOtherIncomePrimary && (
                    <>
                        <Grid item xs={12}>
                            <PopoverField
                                label="monthlyDividendsPrimary"
                                popoverContent="Enter the average monthly income you receive from dividends, including stocks, mutual funds, or other investments."
                            >
                                <CurrencyField
                                    component={TextField}
                                    name="monthlyDividendsPrimary"
                                    label="Monthly Gross Dividend Income (Optional)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>

                        <Grid item xs={12}>
                            <PopoverField
                                label="otherMonthlyIncomePrimary"
                                popoverContent="Enter any additional monthly income not covered by salary or dividends, such as rental income, side business revenue, or other regular payments."
                            >
                                <CurrencyField
                                    component={TextField}
                                    name="otherMonthlyIncomePrimary"
                                    label="Other Gross Monthly Income (Optional)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>

                        <Grid item xs={12}>
                            <PopoverField
                                label="otherIncomeDescriptionPrimary"
                                popoverContent="Provide details about any additional income sources listed above."
                            >
                                <Field
                                    fullWidth
                                    name="otherIncomeDescriptionPrimary"
                                    component={TextField}
                                    label="Description of Income (Optional)"
                                    multiline
                                    rows={2}
                                />
                            </PopoverField>
                        </Grid>
                    </>
                )}
            </>}
            secondary={
                <>
                    <Grid item xs={12}>
                        <PopoverField
                            label="annualSalarySpouse"
                            popoverContent="Enter your spouse's total annual salary before taxes and deductions. Include base salary and any guaranteed bonuses."
                        >
                            <CurrencyField
                                fullWidth
                                name="annualSalarySpouse"
                                component={TextField}
                                label="Annual Gross Salary"
                                type="number"
                            />
                        </PopoverField>
                    </Grid>
                    <Grid item xs={12}>
                        <PopoverField
                            label="hasOtherIncomeSpouse"
                            popoverContent="Select if your spouse has additional sources of income beyond their salary."
                        >
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="hasOtherIncomeSpouse"
                                        type="checkbox"
                                    />
                                }
                                label="My spouse has other sources of income"
                            />
                        </PopoverField>
                    </Grid>

                    {values.hasOtherIncomeSpouse && (
                        <>
                            <Grid item xs={12}>
                                <PopoverField
                                    label="monthlyDividendsSpouse"
                                    popoverContent="Enter the average monthly income your spouse receives from dividends, including stocks, mutual funds, or other investments."
                                >
                                    <CurrencyField
                                        name="monthlyDividendsSpouse"
                                        component={TextField}
                                        label="Monthly Gross Dividend Income (Optional)"
                                        type="number"
                                    />
                                </PopoverField>
                            </Grid>

                            <Grid item xs={12}>
                                <PopoverField
                                    label="otherMonthlyIncomeSpouse"
                                    popoverContent="Enter any additional monthly income not covered by salary or dividends, such as rental income, side business revenue, or other regular payments."
                                >
                                    <CurrencyField
                                        name="otherMonthlyIncomeSpouse"
                                        component={TextField}
                                        label="Other Gross Monthly Income (Optional)"
                                        type="number"
                                    />
                                </PopoverField>
                            </Grid>

                            <Grid item xs={12}>
                                <PopoverField
                                    label="otherIncomeDescriptionSpouse"
                                    popoverContent="Provide details about any additional income sources listed above."
                                >
                                    <Field
                                        fullWidth
                                        name="otherIncomeDescriptionSpouse"
                                        component={TextField}
                                        label="Description of Income (Optional)"
                                        multiline
                                        rows={2}
                                    />
                                </PopoverField>
                            </Grid>
                        </>
                    )}</>}
        />)
};

export const validationSchema = Yup.object({

    annualSalaryPrimary: Yup.number()
        .positive('Salary must be positive')
        .required('Annual salary is required'),
    annualSalarySpouse: Yup.number().when('planType', {
        is: 'couple',
        then: Yup.number().positive('Salary must be positive').required('Annual salary is required'),
        otherwise: (schema) => schema.notRequired(),
    })
});

export default IncomeStep;
