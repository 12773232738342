
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import FormStepContainer from "../FormStepContaner";
import { FormControlLabel, Grid, MenuItem } from '@mui/material';
import { Field } from 'formik';
import PopoverField from '../../components/PopoverField';
import { TextField, Select, Switch } from 'formik-material-ui';
import CurrencyField from "../../components/CurrencyField";
import InputAdornment from '@mui/material/InputAdornment';

const PersonalPensionStep = () => {
    const { values, setFieldValue } = useFormikContext();
    console.log(values)

    const resetPrimaryResidenceMortgageValues = () => {
        setFieldValue('primaryResidenceMortgageValue', 0);
        setFieldValue('primaryResidenceMonthlyMortgagePayment', 0);
        setFieldValue('primaryResidenceMortgageInterestRate', 0);
        setFieldValue('primaryResidenceMortgageInterestRateExpirationDate', '');
        setFieldValue('primaryResidenceRemainingLoanTerm', 0);
    }

    const resetOtherPropertyValues = () => {
        setFieldValue('otherPropertiesNumberOfProperties', 0);
        setFieldValue('otherPropertiesCombinedValue', 0);
        setFieldValue('otherPropertiesCombinedMortgageValue', 0);
        setFieldValue('otherPropertiesCombinedMonthlyMortgagePayments', 0);
        setFieldValue('otherPropertiesAverageRemainingLoanTerm', 0);
        setFieldValue('otherPropertiesCombinedMonthlyGrossIncome', 0);
    }

    const resetOwnsHouseholdPropertyValues = () => {
        setFieldValue('primaryResidenceValue', 0);
        setFieldValue('primaryResidenceOwnershipType', 'single');
        setFieldValue('primaryResidenceHasMortgage', false);
        setFieldValue('ownsOtherProperty', false);
    }

    useEffect(() => {
        if (!values.ownsHouseholdProperty) {
            resetOwnsHouseholdPropertyValues();
        }
    }, [values.ownsHouseholdProperty])

    useEffect(() => {
        if (!values.ownsOtherProperty) {
            resetOtherPropertyValues();
        }
    }, [values.ownsOtherProperty])

    useEffect(() => {
        if (!values.primaryResidenceHasMortgage) {
            resetPrimaryResidenceMortgageValues();
        }
    }, [values.primaryResidenceHasMortgage])

    return (<Grid container spacing={2}>
        <Grid item xs={12}>
            <PopoverField
                label="ownsHouseholdProperty"
                popoverContent="Do you own any property?">
                <FormControlLabel
                    control={
                        <Field
                            component={Switch}
                            name="ownsHouseholdProperty"
                            type="checkbox"
                        />
                    }
                    label="I own property"
                ></FormControlLabel>
            </PopoverField>

        </Grid>
        {values.ownsHouseholdProperty && <>
            <Grid item xs={12}>
                <PopoverField
                    label="primaryResidenceValue"
                    popoverContent="Enter the current value of primary residence."
                >
                    <CurrencyField
                        fullWidth
                        name="primaryResidenceValue"
                        component={TextField}
                        label="Primary Residence Value"
                        type="number"
                    />
                </PopoverField>
            </Grid>
            <Grid item xs={12}>
                <PopoverField
                    label="primaryResidenceOwnershipType"
                    popoverContent="Do you own the property only yourself or with your partner?"
                >
                    <Field
                        sx={{ minWidth: '100%' }}
                        fullWidth
                        name="primaryResidenceOwnershipType"
                        component={Select}
                        label="Primary Residence Ownership Type"
                        formControl={{
                            sx: { minWidth: '100%' }
                        }}
                    >
                        <MenuItem value="single">Only me</MenuItem>
                        <MenuItem value="joint">Joint with my partner</MenuItem>
                    </Field>
                </PopoverField>
            </Grid>
            {/* Select for primaryResidenceHasMortgage boolean */}
            <Grid item xs={12}>
                <PopoverField
                    label="primaryResidenceHasMortgage"
                    popoverContent="Do you have a mortgage?"
                >
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                name="primaryResidenceHasMortgage"
                                type="checkbox"
                            />
                        }
                        label="I have a mortgage"
                    ></FormControlLabel>
                </PopoverField>
            </Grid>
            {values.primaryResidenceHasMortgage && <>

                <Grid item xs={12}>
                    <PopoverField
                        label="primaryResidenceMortgageValue"
                        popoverContent="Enter the current mortgage value of primary residence."
                    >
                        <CurrencyField
                            fullWidth
                            name="primaryResidenceMortgageValue"
                            component={TextField}
                            label="Primary Residence Mortgage Value"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="primaryResidenceMonthlyMortgagePayment"
                        popoverContent="What do you pay per month for your primary residence's mortgage?"
                    >
                        <CurrencyField
                            fullWidth
                            name="primaryResidenceMonthlyMortgagePayment"
                            component={TextField}
                            label="Mortgage Monthly Payment"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="primaryResidenceMortgageInterestRate"
                        popoverContent="What is the interest rate of your primary residence's mortgage?"
                    >
                        <Field
                            fullWidth
                            name="primaryResidenceMortgageInterestRate"
                            component={TextField}
                            label="Interest Rate (%)"
                            type="number"
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                            }}
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="primaryResidenceMortgageInterestRateExpirationDate"
                        popoverContent="What is the interest rate expiration date of your primary mortgage?"
                    >
                        <Field
                            fullWidth
                            name="primaryResidenceMortgageInterestRateExpirationDate"
                            component={TextField}
                            label="Expiration Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="primaryResidenceRemainingLoanTerm"
                        popoverContent="What is the remaining loan term of your primary residence in years?"
                    >
                        <Field
                            fullWidth
                            name="primaryResidenceRemainingLoanTerm"
                            component={TextField}
                            label="Remaining loan term (years)"
                            type="number"
                        />
                    </PopoverField>
                </Grid>
                <Grid item xs={12}>
                    {/* primaryResidenceOutstandingBalance: number */}
                    <PopoverField
                        label="primaryResidenceOutstandingBalance"
                        popoverContent="Enter the outstanding balance of primary residence."
                    >
                        <CurrencyField
                            fullWidth
                            name="primaryResidenceOutstandingBalance"
                            component={TextField}
                            label="Primary Residence Outstanding Balance"
                            type="number"
                        />
                    </PopoverField>
                </Grid>
            </>}

            <Grid item xs={12}>
                <PopoverField
                    label="ownsOtherProperty"
                    popoverContent="Do you own any other property?">
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                name="ownsOtherProperty"
                                type="checkbox"
                            />
                        }
                        label="I own other property"
                    ></FormControlLabel>
                </PopoverField>
            </Grid>

            {values.ownsOtherProperty && <>

                {/* Number field for otherPropertiesNumberOfProperties */}
                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesNumberOfProperties"
                        popoverContent="Enter the number of other properties."
                    >
                        <Field
                            fullWidth
                            name="otherPropertiesNumberOfProperties"
                            component={TextField}
                            label="Number of Properties"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesCombinedValue"
                        popoverContent="Enter the combined value of other properties."
                    >
                        <CurrencyField
                            fullWidth
                            name="otherPropertiesCombinedValue"
                            component={TextField}
                            label="Combined Value"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesCombinedMortgageValue"
                        popoverContent="Enter the combined mortgage value of other properties."
                    >
                        <CurrencyField
                            fullWidth
                            name="otherPropertiesCombinedMortgageValue"
                            component={TextField}
                            label="Combined Mortgage Value"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesCombinedMonthlyMortgagePayments"
                        popoverContent="Enter the combined monthly mortgage payments of other properties."
                    >
                        <CurrencyField
                            fullWidth
                            name="otherPropertiesCombinedMonthlyMortgagePayments"
                            component={TextField}
                            label="Combined Monthly Mortgage Payments"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesAverageRemainingLoanTerm"
                        popoverContent="Enter the average remaining loan term of other properties."
                    >
                        <Field
                            fullWidth
                            name="otherPropertiesAverageRemainingLoanTerm"
                            component={TextField}
                            label="Average Remaining Loan Term (years)"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesOutstandingBalanceTotal"
                        popoverContent="Enter the total outstanding balance of other properties."
                    >
                        <CurrencyField
                            fullWidth
                            name="otherPropertiesOutstandingBalanceTotal"
                            component={TextField}
                            label="Other Properties Outstanding Balance"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

                <Grid item xs={12}>
                    <PopoverField
                        label="otherPropertiesCombinedMonthlyGrossIncome"
                        popoverContent="Enter the combined monthly gross income of other properties."
                    >
                        <CurrencyField
                            fullWidth
                            name="otherPropertiesCombinedMonthlyGrossIncome"
                            component={TextField}
                            label="Combined Monthly Gross Income"
                            type="number"
                        />
                    </PopoverField>
                </Grid>

            </>}

        </>}
    </Grid>)
}

export default PersonalPensionStep;

export const validationSchema = Yup.object().shape({

    ownsHouseholdProperty: Yup.boolean().required(),
    primaryResidenceValue: Yup.number().min(0).when('ownsHouseholdProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    primaryResidenceOwnershipType: Yup.string().when('ownsHouseholdProperty', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired()
    }),
    primaryResidenceHasMortgage: Yup.boolean().required(),
    primaryResidenceMortgageValue: Yup.number().min(0).when('primaryResidenceHasMortgage', {
        is: true,
        then: Yup.number().required(),
        otherwise: Yup.number().notRequired()
    }),
    primaryResidenceMonthlyMortgagePayment: Yup.number().min(0).when('primaryResidenceHasMortgage', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    primaryResidenceMortgageInterestRate: Yup.number().min(0).when('primaryResidenceHasMortgage', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    primaryResidenceMortgageInterestRateExpirationDate: Yup.date().when('primaryResidenceHasMortgage', {
        is: true,
        then: Yup.date().required(),
        otherwise: Yup.date().notRequired()
    }),
    primaryResidenceRemainingLoanTerm: Yup.number().min(0).when('primaryResidenceHasMortgage', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    primaryResidenceOutstandingBalance: Yup.number().min(0).when('primaryResidenceHasMortgage', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    ownsOtherProperty: Yup.boolean().required(),
    otherPropertiesNumberOfProperties: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    otherPropertiesCombinedValue: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    otherPropertiesCombinedMortgageValue: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    otherPropertiesCombinedMonthlyMortgagePayments: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    otherPropertiesAverageRemainingLoanTerm: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    otherPropertiesOutstandingBalanceTotal: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    }),
    otherPropertiesCombinedMonthlyGrossIncome: Yup.number().min(0).when('ownsOtherProperty', {
        is: true,
        then: Yup.number().min(0).required(),
        otherwise: Yup.number().notRequired()
    })
})