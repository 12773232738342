import React from 'react';
import {Grid, Typography} from '@mui/material';
import {useFormikContext} from 'formik';
import * as Yup from 'yup';

const FormStepContainer = ({primary, secondary, showNames = true}) => {
    const {values} = useFormikContext();
    const isCouple = values.planType === 'couple';

    return (
        <Grid container spacing={2}>
            {/* Primary Income Fields */}
            <Grid item xs={12} md={isCouple ? 6 : 12}>
                <Typography variant="h6" gutterBottom style={{paddingBottom: '16px'}}>
                    {showNames ? <>{values.namePrimary} {values.surnamePrimary}</> : "Primary Applicant"}
                </Typography>
                <Grid container spacing={2}>

                    {primary}
                </Grid>
            </Grid>

            {/* Spouse Income Fields */}
            {isCouple && (
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom style={{paddingBottom: '16px'}}>
                        {showNames ? <>{values.nameSpouse} {values.surnameSpouse}</> : "Secondary Applicant"}
                    </Typography>
                    <Grid container spacing={2}>
                        {secondary}
                    </Grid>
                </Grid>

            )}
        </Grid>
    );
};

export const validationSchema = Yup.object({

    annualSalaryPrimary: Yup.number()
        .positive('Salary must be positive')
        .required('Annual salary is required'),

    // Spoouse
    // Spouse (conditional based on planType)
    annualSalarySpouse: Yup.number()
        .when('planType', {
            is: 'couple',
            then: (schema) => schema
                .positive('Salary must be positive')
                .required('Annual salary is required'),
            otherwise: (schema) => schema.notRequired(),
        })
});

export default FormStepContainer;
