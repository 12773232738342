import React from 'react';
import {Grid} from '@mui/material';
import {Field, useFormikContext} from 'formik';
import {TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import FormStepContainer from "../FormStepContaner";

const PersonalStep = () => {
    const {values} = useFormikContext();
    const isCouple = values.planType === 'couple';
    return (

        <FormStepContainer
            showNames={false}
            primary={<>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        name="namePrimary"
                        component={TextField}
                        label="Name"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        name="surnamePrimary"
                        component={TextField}
                        label="Surname"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        name="emailPrimary"
                        component={TextField}
                        label="Email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        name="dobPrimary"
                        component={TextField}
                        label="Date of Birth"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </>}
            secondary={
                <>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name="nameSpouse"
                            component={TextField}
                            label="Name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name="surnameSpouse"
                            component={TextField}
                            label="Surname"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name="dobSpouse"
                            component={TextField}
                            label="Date of Birth"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>
            }
        />
    )
        ;
};

export default PersonalStep;


export const validationSchema = Yup.object({
    // Personal
    namePrimary: Yup.string().required('Name is required'),
    surnamePrimary: Yup.string().required('Surname is required'),
    emailPrimary: Yup.string().required('Email is required'),
    dobPrimary: Yup.date().required('Date of Birth is required'),


    // Spoouse
    // Spouse (conditional based on planType)
    nameSpouse: Yup.string()
        .when('planType', {
            is: 'couple',
            then: (schema) => schema.required('Name is required'),
            otherwise: (schema) => schema.notRequired(),
        }),
    surnameSpouse: Yup.string()
        .when('planType', {
            is: 'couple',
            then: (schema) => schema.required('Surname is required'),
            otherwise: (schema) => schema.notRequired(),
        }),
    dobSpouse: Yup.date()
        .when('planType', {
            is: 'couple',
            then: (schema) => schema.required('Date of Birth is required'),
            otherwise: (schema) => schema.notRequired(),
        }),

});
