import React, { useEffect, useState } from "react";
import { Form } from "formik";
import { Button, Stack, Step, StepLabel, Stepper, Typography, Grid, Box, Avatar, Paper } from "@mui/material";
import axios from "axios";
import { Formik, useFormikContext } from "formik";
import { validationSchema } from "./validationSchema";
import { Nav } from './forms/nav'
import PersonalStep, { validationSchema as personalStepValidationSchema } from "./forms/steps/PersonalStep.js";
import ExpenditureStep, { validationSchema as expenditureStepValidationSchema } from "./forms/steps/ExpenditureStep.js";
import PersonalPensionStep, { validationSchema as pensionStepValidationSchema } from "./forms/steps/PersonalPensionStep.js";
import InvestmentsStep, { validationSchema as investmentsStepValidationSchema } from "./forms/steps/InvestmentsStep.js";
import CashStep, { validationSchema as cashStepValidationSchema } from "./forms/steps/CashStep.js";
import IntroStep, { validationSchema as introStepValidationSchema } from "./forms/steps/IntroStep.js"
import stringify from 'json-stringify-pretty-compact';
import { MobileStepper, StepContent } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import IncomeStep, { validationSchema as incomeStepValidationSchema } from "./forms/steps/IncomeStep.js";
import HouseholdPropertyStep, { validationSchema as propertyValidationSchema } from "./forms/steps/HouseholdProperty.js";


const stepsArray = [
  <IntroStep key="intro" validationSchema={introStepValidationSchema} label="Intro" buttonText="Let's go!" />,
  <PersonalStep key="person1" validationSchema={personalStepValidationSchema} label="Personal Information" />,
  <IncomeStep key="person1" validationSchema={incomeStepValidationSchema} label="Income" />,
  <ExpenditureStep key="expenditure" validationSchema={expenditureStepValidationSchema} label="Household Expenditure" />,
  <PersonalPensionStep key="pension" validationSchema={pensionStepValidationSchema} label="Pension" />,
  <InvestmentsStep key="investments" validationSchema={investmentsStepValidationSchema} label="Investments" />,
  <CashStep key="cash" validationSchema={cashStepValidationSchema} label="Cash" />,
  <HouseholdPropertyStep key="property" validationSchema={propertyValidationSchema} label="Household Property" buttonText="Finish" />,
];

export const Wizard = () => {

  // const initialValues = {
  //   planType: "", // or "single"
  //   // Personal
  //   namePrimary: "",
  //   nameSpouse: "",
  //   surnamePrimary: "",
  //   surnameSpouse: "",
  //   emailPrimary: "",
  //   emailSpouse: "",
  //   dobPrimary: "",
  //   dobSpouse: "",
  //   retirementAgePrimary: "",
  //   retirementAgeSpouse: "",

  //   // Income
  //   occupationPrimary: "",
  //   annualSalaryPrimary: 0,
  //   hasOtherIncomePrimary: false,
  //   otherGrossMontlyIncomePrimary: "",
  //   otherMonthlyIncomeDescriptionPrimary: "",

  //   occupationSpouse: "",
  //   annualSalarySpouse: 0,
  //   hasOtherIncomeSpouse: false,
  //   otherGrossMontlyIncomeSpouse: "",
  //   otherMonthlyIncomeDescriptionSpouse: "",

  //   // Expenditure
  //   monthlyEssentialSpending: "",
  //   monthlyDiscretionarySpending: "",

  //   // Pension
  //   hasPensionsPrimary: false,
  //   currentPensionValuePrimary: "",
  //   currentPensionProviderDetailsPrimary: "",
  //   annualContributions: {
  //     employee: { type: "percentage", cashContribution: 0, percentage: 0 },
  //     employer: { type: "percentage", cashContribution: 0, percentage: 0 },
  //   },
  //   primaryKnowsPensionGrowthRate: false,
  //   annualGrowthRate: 0,
  //   primaryKnowsPensionFees: false,
  //   annualFees: 0,
  //   employerMakesContributionsPrimary: false,
  //   makesContributionsPrimary: false,
  //   monthlyContributionsPrimary: 0,
  //   monthlyContributionsPartner: 0,
  //   fullNIContributionPrimary: false,
  //   niContributionYearsPrimary: "",

  //   // Pension Partner
  //   hasPensionsPartner: false,
  //   currentPensionValuePartner: "",
  //   currentPensionProviderDetailsPartner: "",
  //   partnerKnowsPensionGrowthRate: false,
  //   annualGrowthRatePartner: 0,
  //   partnerKnowsPensionFees: false
  //   annualFeesPartner: 0,
  //   annualContributionsPartner: {
  //     employee: { type: "percentage", cashContribution: 0, percentage: 0 },
  //     employer: { type: "percentage", cashContribution: 0, percentage: 0 },
  //   },
  //   employerMakesContributionsPartner: false,
  //   makesContributionsPartner: false,
  //   fullNIContributionPartner: false,
  //   niContributionYearsPartner: "25",

  // // Investments
    // hasInvestments: false,
    // contributesMonthlyToInvestments: false,
    // investmentsMonthlyContribution: 0,
    // investmentCurrentValue: 0,
    // knowsInvestmentGrowthRate: false,
    // investmentAnnualGrowthRate: 0,
    // knowsInvestmentAnnualFees: false,
    // investmentAnnualFees: 0,

  //   // Cash
  //   cashCurrentValue: "",
  //   cashInterestRate: 0,

  //   // Household Property
  //   ownsHouseholdProperty: true,
  //   primaryResidenceValue: 0,
  //   primaryResidenceOwnershipType: "single",

  //   primaryResidenceHasMortgage: true,
  //   primaryResidenceMortgageValue: 0,
  //   primaryResidenceMonthlyMortgagePayment: 0,
  //   primaryResidenceMortgageInterestRate: 0,
  //   primaryResidenceMortgageInterestRateExpirationDate: "2035-01-22",
  //   primaryResidenceRemainingLoanTerm: 0,
  //   primaryResidenceOutstandingBalance: 0,

  //   ownsOtherProperty: true,
  //   otherPropertiesNumberOfProperties: 0,
  //   otherPropertiesCombinedValue: 0,
  //   otherPropertiesCombinedMortgageValue: 0,
  //   otherPropertiesCombinedMonthlyMortgagePayments: 0,
  //   otherPropertiesAverageRemainingLoanTerm: 0,
  //   otherPropertiesOutstandingBalance: 0,
  //   otherPropertiesCombinedMonthlyGrossIncome: 0,
  // };

  //  Values filled in for testing
  const initialValues = {
    planType: "couple", // or "single"
    // Personal
    namePrimary: "John",
    nameSpouse: "Jane",
    surnamePrimary: "Smith",
    surnameSpouse: "Smith",
    emailPrimary: "john.smith@example.com",
    emailSpouse: "jane.smith@example.com",
    dobPrimary: "1980-05-15",
    dobSpouse: "1982-03-20",
    retirementAgePrimary: "65",
    retirementAgeSpouse: "65",

    // Income
    occupationPrimary: "Software Engineer",
    annualSalaryPrimary: 8500,
    hasOtherIncomePrimary: false,
    otherGrossMontlyIncomePrimary: "1000",
    otherMonthlyIncomeDescriptionPrimary: "Rental Income",

    occupationSpouse: "Marketing Manager",
    annualSalarySpouse: 8500,
    hasOtherIncomeSpouse: false,
    otherGrossMontlyIncomeSpouse: "",
    otherMonthlyIncomeDescriptionSpouse: "",

    // Expenditure
    monthlyEssentialSpending: "45000",
    monthlyDiscretionarySpending: "25000",

    // Pension
    hasPensionsPrimary: true,
    currentPensionValuePrimary: 1000,
    currentPensionProviderDetailsPrimary: "ProviderName",
    annualContributions: {
      employee: { type: "fixed", cashContribution: 10, percentage: 0.12 },
      employer: { type: "percentage", cashContribution: 1190, percentage: 14 },
    },
    primaryKnowsPensionGrowthRate: true,
    annualGrowthRate: 5,
    primaryKnowsPensionFees: true,
    annualFees: 3,
    employerMakesContributionsPrimary: true,
    makesContributionsPrimary: true,
    monthlyContributionsPrimary: 0,
    monthlyContributionsPartner: 0,
    fullNIContributionPrimary: false,
    niContributionYearsPrimary: 25,

    // Pension Partner
    hasPensionsPartner: true,
    currentPensionValuePartner: 1000,
    currentPensionProviderDetailsPartner: "ProviderName",
    partnerKnowsPensionGrowthRate: true,
    annualGrowthRatePartner: 6,
    partnerKnowsPensionFees: true,
    annualFeesPartner: 2,
    annualContributionsPartner: {
      employee: { type: "percentage", cashContribution: 850, percentage: 10 },
      employer: { type: "fixed", cashContribution: 500, percentage: 5.88 },
    },
    employerMakesContributionsPartner: true,
    makesContributionsPartner: true,
    fullNIContributionPartner: true,
    niContributionYearsPartner: 35,

    // Investments
    // hasInvestments: true,
    // contributesMonthlyToInvestments: true,
    // investmentsMonthlyContribution: 500,
    // investmentCurrentValue: 1500,
    // knowsInvestmentGrowthRate: true,
    // investmentAnnualGrowthRate: 4,
    // knowsInvestmentAnnualFees: true,
    // investmentAnnualFees: 1,

    hasInvestments: false,
    contributesMonthlyToInvestments: false,
    investmentsMonthlyContribution: 0,
    investmentCurrentValue: 0,
    knowsInvestmentGrowthRate: false,
    investmentAnnualGrowthRate: 0,
    knowsInvestmentAnnualFees: false,
    investmentAnnualFees: 0,

    // investmentAnnualContributions: "12000",

    // Cash
    cashCurrentValue: "50000",
    cashInterestRate: 2,

    // Household Property
    ownsHouseholdProperty: true,
    primaryResidenceValue: 50000,
    primaryResidenceOwnershipType: "single",

    primaryResidenceHasMortgage: true,
    primaryResidenceMortgageValue: 50000,
    primaryResidenceMonthlyMortgagePayment: 500,
    primaryResidenceMortgageInterestRate: 3,
    primaryResidenceMortgageInterestRateExpirationDate: "2035-01-22",
    primaryResidenceRemainingLoanTerm: 5,
    primaryResidenceOutstandingBalance: 5000,

    ownsOtherProperty: true,
    otherPropertiesNumberOfProperties: 3,
    otherPropertiesCombinedValue: 15000,
    otherPropertiesCombinedMortgageValue: 2500,
    otherPropertiesCombinedMonthlyMortgagePayments: 800,
    otherPropertiesAverageRemainingLoanTerm: 35,
    otherPropertiesOutstandingBalanceTotal: 5000,
    otherPropertiesCombinedMonthlyGrossIncome: 3200,
  };

  const [step, setStep] = useState(5);
  const currentStep = stepsArray[step];

  const handleSubit = async (values) => {

    try {
      // Prepare the data for submission
      const formData = {
        title: `${values.name} ${values.surname} ${values.email}`,
        content: stringify(values, {
          indent: 2,
          maxLength: 80
        })
      };

      console.log("Form data:", formData);

      // const response = await axios.post('https://app.pleniry.com/api/form-responses/post', formData);

      alert("Form submitted successfully")
      // console.log('Form submitted successfully:', response.data);
    } catch (error) {
      alert("Error submitting form")
      console.error('Error submitting form:', error);

      // Set an error status

    } finally {

    }
  }

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const View = isMobile ? Mobile : Desktop;

  return (
    <Grid container spacing={3}>

      <View step={step}>
        <Formik

          initialValues={initialValues}
          validationSchema={currentStep.props.validationSchema}
          onSubmit={handleSubit}
        >
          <Form style={{ marginTop: '2em', marginBottom: '2em' }}>
            {currentStep}
            <Nav step={currentStep} stepIndex={step} steps={stepsArray} onSubmit={handleSubit} setStep={setStep} />
          </Form>
        </Formik>

      </View>


    </Grid>
  );
};

const Desktop = ({ children, step }) => {
  return (
    <>

      <Grid item xs={12} md={4} lg={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Stepper activeStep={step} orientation="vertical">
          {stepsArray.map((child, index) => (
            <Step key={child.props.label} completed={step > index}>
              <StepLabel>{child.props.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Step {step + 1} / {stepsArray.length} <b>{stepsArray[step].props.label}</b>
        </Typography>

        <hr />
        <br />

        {children}
      </Grid></>
  );
};

function Mobile({ step, children }) {
  return (
    <Grid item xs={12}>
      <Box sx={{ margin: '0 auto' }}>
        <Stepper activeStep={step} orientation="vertical">
          {stepsArray.map((_, index) => (
            <Step key={stepsArray[index].props.label}>
              <StepLabel>
                {stepsArray[index].props.label}
              </StepLabel>
              <StepContent>
                {children}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Grid>
  );
}