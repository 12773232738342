import React, { useEffect } from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import PopoverField from '../../components/PopoverField';
import { TextField, Select, Switch } from 'formik-material-ui';
import CurrencyField from "../../components/CurrencyField";
import { FormControlLabel, Grid, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';


const InvestmentsStep = () => {

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.hasInvestments === false) {
      setFieldValue('contributesMonthlyToInvestments', false);
      setFieldValue('investmentsMonthlyContribution', 0);
      setFieldValue('investmentCurrentValue', 0);
      setFieldValue('knowsInvestmentGrowthRate', false);
      setFieldValue('investmentAnnualGrowthRate', 0);
      setFieldValue('knowsInvestmentAnnualFees', false);
      setFieldValue('investmentAnnualFees', 0);
    } else {
      if (values.knowsInvestmentGrowthRate === false) {
        setFieldValue('investmentAnnualGrowthRate', 6);
      }
      if (values.knowsInvestmentAnnualFees === false) {
        setFieldValue('investmentAnnualFees', 2);
      }
      if (values.contributesMonthlyToInvestments === false) {
        setFieldValue('investmentsMonthlyContribution', 0);
      }
    }

  }, [values.hasInvestments, values.knowsInvestmentGrowthRate, values.knowsInvestmentAnnualFees, values.contributesMonthlyToInvestments])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PopoverField
          label="hasInvestments"
          popoverContent="Do you have investments? ">
          <FormControlLabel
            control={
              <Field
                component={Switch}
                name="hasInvestments"
                type="checkbox"
              />
            }
            label="I have investments"
          ></FormControlLabel>
        </PopoverField>
      </Grid>

      {values.hasInvestments && <>

        <Grid item xs={12}>
          <PopoverField
            label="investmentCurrentValue"
            popoverContent="Enter the current value of your current investments."
          >
            <CurrencyField
              fullWidth
              name="investmentCurrentValue"
              component={TextField}
              label="Investment Current Value"
              type="number"
            />
          </PopoverField>
        </Grid>


        <Grid item xs={12}>
          <PopoverField
            label="contributesMonthlyToInvestments"
            popoverContent="Do you contribute to your investments monthly?">
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  name="contributesMonthlyToInvestments"
                  type="checkbox"
                />
              }
              label="I contribute monthly to my investments"
            ></FormControlLabel>
          </PopoverField>
        </Grid>


        {values.contributesMonthlyToInvestments && <>
          <Grid item xs={12}>
            <PopoverField
              label="investmentsMonthlyContribution"
              popoverContent="Enter the current value of primary residence."
            >
              <CurrencyField
                fullWidth
                name="investmentsMonthlyContribution"
                component={TextField}
                label="Monthly Investment Contribution"
                type="number"
              />
            </PopoverField>
          </Grid>
        </>}

        <Grid item xs={12}>
          <PopoverField
            label="knowsInvestmentGrowthRate"
            popoverContent="Do you know the annual growth rate of your investments?">
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  name="knowsInvestmentGrowthRate"
                  type="checkbox"
                />
              }
              label="I know the annual growth rate of my investments"
            ></FormControlLabel>
          </PopoverField>
        </Grid>

        {values.knowsInvestmentGrowthRate && <>
          <Grid item xs={12}>
            <PopoverField
              label="investmentAnnualGrowthRate"
              popoverContent="Enter the annual growth rate of your investments.">
              <Field
                fullWidth
                name="investmentAnnualGrowthRate"
                component={TextField}
                label="Annual Growth Rate (%)"
                type="number"
              />
            </PopoverField>
          </Grid></>}

        <Grid item xs={12}>
          <PopoverField
            label="knowsInvestmentAnnualFees"
            popoverContent="Do you know the annual fees of your investments?">
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  name="knowsInvestmentAnnualFees"
                  type="checkbox"
                />
              }
              label="I know the annual fees of my investments"
            ></FormControlLabel>
          </PopoverField>
        </Grid>

        {values.knowsInvestmentAnnualFees && <>
          <Grid item xs={12}>
            <PopoverField
              label="investmentAnnualFees"
              popoverContent="Enter the annual fees of your investments.">
              <Field
                fullWidth
                name="investmentAnnualFees"
                component={TextField}
                label="Annual Fees (%)"
                type="number"
              />
            </PopoverField>
          </Grid></>}

      </>}

    </Grid>
  );
};

export default InvestmentsStep;

export const validationSchema = Yup.object({

  hasInvestments: Yup.boolean().required('Please select if you have investments'),
  investmentCurrentValue: Yup.number().when('hasInvestments', {
    is: true,
    then: Yup.number().min(1).required('Investment Current Value is required'),
    otherwise: Yup.number().oneOf([0], 'Investment Current Value must be 0 if you do not have investments'),
  }),
  contributesMonthlyToInvestments: Yup.boolean().when('hasInvestments', {
    is: true,
    then: Yup.boolean().required('Please select if you contribute to your investments monthly'),
    otherwise: Yup.boolean().oneOf([false], 'Please select if you contribute to your investments monthly'),
  }),
  investmentsMonthlyContribution: Yup.number().when('hasInvestments', {
    is: true,
    then: Yup.number().when('contributesMonthlyToInvestments', {
      is: true,
      then: Yup.number().min(1).required('Monthly Investment Contribution is required'),
      otherwise: Yup.number().oneOf([0], 'Monthly Investment must be 0 if you do not contribute to your investments monthly'),
    }),
    otherwise: Yup.number().oneOf([0], 'Monthly Investment must be 0 if you do not have investments'),
  }),
  knowsInvestmentGrowthRate: Yup.boolean().when('hasInvestments', {
    is: true,
    then: Yup.boolean().required('Please select if you know the annual growth rate of your investments'),
    otherwise: Yup.boolean().oneOf([false], 'Please select if you know the annual growth rate of your investments'),
  }),
  // investmentAnnualGrowthRate: Yup.number().when('hasInvestments', {
  //   is: true,
  //   then: Yup.number().when('knowsInvestmentGrowthRate', {
  //     is: true,
  //     then: Yup.number().min(0).max(100).required('Annual Growth Rate is required'),
  //     otherwise: Yup.number().oneOf([6], 'Annual Growth Rate must be 6 if you do not know the annual growth rate of your investments'),
  //   }),
  //   otherwise: Yup.number().oneOf([0], 'Annual Growth Rate must be 0 if you do not have investments'),
  // }),
  knowsInvestmentAnnualFees: Yup.boolean().when('hasInvestments', {
    is: true,
    then: Yup.boolean().required('Please select if you know the annual fees of your investments'),
    otherwise: Yup.boolean().oneOf([false], 'Please select if you know the annual fees of your investments'),
  }),
  investmentAnnualFees: Yup.number().when('hasInvestments', {
    is: true,
    then: Yup.number().when('knowsInvestmentAnnualFees', {
      is: true,
      then: Yup.number().min(0).max(100).required('Annual Fees is required'),
      otherwise: Yup.number().oneOf([2], 'Annual Fees must be 2 if you do not know the annual fees of your investments'),
    }),
    otherwise: Yup.number().oneOf([0], 'Annual Fees must be 0 if you do not have investments'),
  }),
})
