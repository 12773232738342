import React, { useEffect, useRef } from 'react';
import { Grid, MenuItem, FormControlLabel, Typography, Link } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField, Select, Switch } from 'formik-material-ui';
import * as Yup from 'yup';
import PopoverField from "../../components/PopoverField";
import CurrencyField from "../../components/CurrencyField";
import FormStepContainer from "../FormStepContaner";
import InputAdornment from '@mui/material/InputAdornment';

const PersonalPensionStep = () => {
    const { values, setFieldValue } = useFormikContext();
    const hasPensionsPrimary = values.hasPensionsPrimary;
    const fullNIContributionPrimary = values.fullNIContributionPrimary;
    const fullNIContributionPartner = values.fullNIContributionPartner;
    const makesContributionsPrimary = values.makesContributionsPrimary;
    const makesContributionsPartner = values.makesContributionsPartner;
    const hasPensionsPartner = values.hasPensionsPartner;
    const employerMakesContributionsPrimary = values.employerMakesContributionsPrimary;
    const employerMakesContributionsPartner = values.employerMakesContributionsPartner;

    /* =================== Effects Related to Primary =================== */
    // This is to keep the percentage and cash contribution in sync for employer contributions. 
    useEffect(() => {
        if (values.annualContributions.employer.type === "percentage") {
            values.annualContributions.employer.cashContribution = Number(values.annualSalaryPrimary) * values.annualContributions.employer.percentage / 100;
        } else {
            const cashContributionPercentage = Math.round((values.annualContributions.employer.cashContribution / values.annualSalaryPrimary) * 100 * 100) / 100;
            setFieldValue("annualContributions.employer.percentage", cashContributionPercentage)
        }
    }, [values.annualContributions.employer.cashContribution, values.annualContributions.employer.percentage]);

    // This is to reset the annual growth rate when the switch is toggled off.
    useEffect(() => {
        if (!values.primaryKnowsPensionGrowthRate) {
            setFieldValue("annualGrowthRate", 0);
        }
    }, [values.primaryKnowsPensionGrowthRate]);

    // This is to reset the annual fees when the switch is toggled off.
    useEffect(() => {
        if (!values.primaryKnowsPensionFees) {
            setFieldValue("annualFees", 0);
        }
    }, [values.primaryKnowsPensionFees]);

    // This is to reset the employer contributions when the switch is toggled off.
    useEffect(() => {
        if (!employerMakesContributionsPrimary) {
            setFieldValue("annualContributions.employer.cashContribution", 0);
            setFieldValue("annualContributions.employer.percentage", 0);
        }
    }, [employerMakesContributionsPrimary])

    // This is to keep the percentage and cash contribution in sync for employee contributions.
    useEffect(() => {
        if (values.annualContributions.employee.type === "percentage") {
            values.annualContributions.employee.cashContribution = Number(values.annualSalaryPrimary) * values.annualContributions.employee.percentage / 100;
        } else {
            const cashContributionPercentage = Math.round((values.annualContributions.employee.cashContribution / values.annualSalaryPrimary) * 100 * 100) / 100;
            setFieldValue("annualContributions.employee.percentage", cashContributionPercentage)
        }
    }, [values.annualContributions.employee.cashContribution, values.annualContributions.employee.percentage]);

    // This is to reset the employee contributions when the switch is toggled off.
    useEffect(() => {
        if (!makesContributionsPrimary) {
            setFieldValue("annualContributions.employee.cashContribution", 0);
            setFieldValue("annualContributions.employee.percentage", 0);
        }
    }, [makesContributionsPrimary])


    // This is to set the NI contributions when the switch is toggled.
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (fullNIContributionPrimary) {
            setFieldValue("niContributionYearsPrimary", 35);
        } else {
            setFieldValue("niContributionYearsPrimary", 0);
        }
    }, [fullNIContributionPrimary])

    // This is to reset the whole page if hasPensionsPrimary is toggled off.
    useEffect(() => {
        if (!hasPensionsPrimary) {
            setFieldValue("currentPensionValuePrimary", 0);
            setFieldValue("currentPensionProviderDetailsPrimary", "");
            setFieldValue("primaryKnowsPensionGrowthRate", false);
            setFieldValue("primaryKnowsPensionFees", false);
            setFieldValue("annualGrowthRate", 0);
            setFieldValue("annualFees", 0);
            setFieldValue("employerMakesContributionsPrimary", false);
            setFieldValue("makesContributionsPrimary", false);
            setFieldValue("annualContributions.employer.type", "percentage");
            setFieldValue("annualContributions.employer.percentage", 0);
            setFieldValue("annualContributions.employer.cashContribution", 0);
            setFieldValue("annualContributions.employee.type", "percentage");
            setFieldValue("annualContributions.employee.percentage", 0);
            setFieldValue("annualContributions.employee.cashContribution", 0);
            setFieldValue("fullNIContributionPrimary", false);
            setFieldValue("niContributionYearsPrimary", 0);
        }
    }, [hasPensionsPrimary])

    /* =================== Effects related to Partner =================== */
    // This is to keep the percentage and cash contribution in sync for employer contributions. 
    useEffect(() => {
        console.log('issue is here')
        if (values.annualContributionsPartner.employer.type === "percentage") {
            values.annualContributionsPartner.employer.cashContribution = Number(values.annualSalarySpouse) * values.annualContributionsPartner.employer.percentage / 100;
        } else {
            const cashContributionPercentage = Math.round((values.annualContributionsPartner.employer.cashContribution / values.annualSalarySpouse) * 100 * 100) / 100;
            setFieldValue("annualContributionsPartner.employer.percentage", cashContributionPercentage)
        }
    }, [values.annualContributionsPartner.employer.cashContribution, values.annualContributionsPartner.employer.percentage]);

    // This is to reset the partner annual growth rate when the switch is toggled off.
    useEffect(() => {
        if (!values.partnerKnowsPensionGrowthRate) {
            setFieldValue("annualGrowthRatePartner", 0);
        }
    }, [values.partnerKnowsPensionGrowthRate]);

    // This is to reset the partner annual fees when the switch is toggled off.
    useEffect(() => {
        if (!values.partnerKnowsPensionFees) {
            setFieldValue("annualFeesPartner", 0);
        }
    }, [values.partnerKnowsPensionFees]);

    // This is to reset the employer contributions when the switch is toggled off.
    useEffect(() => {
        if (!employerMakesContributionsPartner) {
            setFieldValue("annualContributionsPartner.employer.cashContribution", 0);
            setFieldValue("annualContributionsPartner.employer.percentage", 0);
        }
    }, [employerMakesContributionsPartner])

    // This is to keep the percentage and cash contribution in sync for employee contributions.
    useEffect(() => {
        if (values.annualContributionsPartner.employee.type === "percentage") {
            values.annualContributionsPartner.employee.cashContribution = Number(values.annualSalarySpouse) * values.annualContributionsPartner.employee.percentage / 100;
        } else {
            const cashContributionPercentage = Math.round((values.annualContributionsPartner.employee.cashContribution / values.annualSalarySpouse) * 100 * 100) / 100;
            setFieldValue("annualContributionsPartner.employee.percentage", cashContributionPercentage)
        }
    }, [values.annualContributionsPartner.employee.cashContribution, values.annualContributionsPartner.employee.percentage]);

    // This is to reset the employee contributions when the switch is toggled off.
    useEffect(() => {
        if (!makesContributionsPartner) {
            setFieldValue("annualContributionsPartner.employee.cashContribution", 0);
            setFieldValue("annualContributionsPartner.employee.percentage", 0);
        }
    }, [makesContributionsPartner])

    // This is to set the NI contributions when the switch is toggled.
    useEffect(() => {
        if (fullNIContributionPartner) {
            setFieldValue("niContributionYearsPartner", 35);
        } else {
            setFieldValue("niContributionYearsPartner", 0);
        }
    }, [fullNIContributionPartner])

    // This is to reset the whole page if hasPensionsPrimary is toggled off.
    useEffect(() => {
        if (!hasPensionsPartner) {
            setFieldValue("currentPensionValuePartner", 0);
            setFieldValue("currentPensionProviderDetailsPartner", "");
            setFieldValue("annualGrowthRatePartner", 0);
            setFieldValue("annualFeesPartner", 0);
            setFieldValue("employerMakesContributionsPartner", false);
            setFieldValue("makesContributionsPartner", false);
            setFieldValue("annualContributionsPartner.employer.type", "percentage");
            setFieldValue("annualContributionsPartner.employer.percentage", 0);
            setFieldValue("annualContributionsPartner.employer.cashContribution", 0);
            setFieldValue("annualContributionsPartner.employee.type", "percentage");
            setFieldValue("annualContributionsPartner.employee.percentage", 0);
            setFieldValue("annualContributionsPartner.employee.cashContribution", 0);
            setFieldValue("fullNIContributionPartner", false);
            setFieldValue("niContributionYearsPartner", 0);
        }
    }, [hasPensionsPartner])

    useEffect(() => { console.log('values', values) }, [values])

    return (

        <FormStepContainer
            /* =================== JSX for Primary =================== */

            primary={<>
                <PopoverField
                    label="hasPensionsPrimary"
                    popoverContent="Please toggle this switch if you have any pensions as the primary account holder."
                >
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                name="hasPensionsPrimary"
                                type="checkbox"
                            />
                        }
                        label="I have pensions"
                    />
                </PopoverField>

                {hasPensionsPrimary && (
                    <>
                        <Grid item xs={12}>
                            <PopoverField
                                label="currentPensionValuePrimary"
                                popoverContent="Enter the current value of your pension."
                            >
                                <CurrencyField
                                    fullWidth
                                    name="currentPensionValuePrimary"
                                    component={TextField}
                                    label="Current Combined Pension Value (all pensions)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="providerDetails"
                                popoverContent="Enter pension provider details."
                            >
                                <Field
                                    fullWidth
                                    name="currentPensionProviderDetailsPrimary"
                                    component={TextField}
                                    label="Current Pension Provider Name"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="primaryKnowsPensionGrowthRate"
                                popoverContent="Please toggle this switch if you know the growth rate of your pension. If not, an average value will be used."
                            >
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            name="primaryKnowsPensionGrowthRate"
                                            type="checkbox"
                                        />
                                    }
                                    label="I know the growth rate of my pension"
                                />
                            </PopoverField>
                        </Grid>
                        {values.primaryKnowsPensionGrowthRate && (
                            <Grid item xs={12}>
                                <PopoverField
                                    label="annualGrowthRate"
                                    popoverContent="Enter the expected annual growth rate of your pension."
                                >
                                    <Field
                                        fullWidth
                                        name="annualGrowthRate"
                                        component={TextField}
                                        label="Annual Growth Rate (%)"
                                        type="number"
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                        }}
                                    />
                                </PopoverField>
                            </Grid>)}
                        <Grid item xs={12}>
                            <PopoverField
                                label="primaryKnowsPensionFees"
                                popoverContent="Please toggle this switch if you know the annual fees of your pension fund. If not, an average value will be used."
                            >
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            name="primaryKnowsPensionFees"
                                            type="checkbox"
                                        />
                                    }
                                    label="I know my pension's annual fees."
                                />
                            </PopoverField>
                        </Grid>
                        {values.primaryKnowsPensionFees && (<Grid item xs={12}>
                            <PopoverField
                                label="annualFees"
                                popoverContent="Enter the annual fees associated with your pension."
                            >
                                <Field
                                    fullWidth
                                    name="annualFees"
                                    component={TextField}
                                    label="Annual Fees (%)"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                    }}
                                />
                            </PopoverField>
                        </Grid>)}
                        <Grid item xs={12}>
                            <PopoverField
                                label="employerMakesContributionsPrimary"
                                popoverContent="Please toggle this switch if your employer is currently contributing to a pension."
                            >
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            name="employerMakesContributionsPrimary"
                                            type="checkbox"
                                        />
                                    }
                                    label="Is your employer currently contributing to a pension?"
                                />
                            </PopoverField>
                        </Grid>
                        {employerMakesContributionsPrimary && (<>

                            <Grid item xs={12}>
                                <PopoverField
                                    label="employerContributionType"
                                    popoverContent="Select the type of employer contribution."
                                >
                                    <Field
                                        fullWidth
                                        name="annualContributions.employer.type"
                                        component={Select}
                                        label="Employer Contribution Type"
                                    >
                                        <MenuItem value="percentage">Percentage of Salary</MenuItem>
                                        <MenuItem value="fixed">Fixed Amount</MenuItem>
                                    </Field>
                                </PopoverField>
                            </Grid>


                            {values.annualContributions.employer.type === "percentage" && (
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employerContributionPercentage"
                                        popoverContent="Enter the percentage of employer contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributions.employer.percentage"
                                            component={TextField}
                                            label="Employer Contribution %"
                                            type="number"
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                            }}
                                        />
                                    </PopoverField>
                                </Grid>)}
                            <Grid item xs={12}>
                                <PopoverField
                                    label="employerContributionValue"
                                    popoverContent="Enter the value of employer contribution."
                                >
                                    <Field
                                        fullWidth
                                        name="annualContributions.employer.cashContribution"
                                        component={TextField}
                                        label="Employer Contribution Amount"
                                        type="number"
                                        disabled={values.annualContributions.employer.type === "percentage"}
                                        InputProps={{
                                            endAdornment: (values.annualContributions.employer.type === "percentage" && <InputAdornment position="end">%</InputAdornment>),
                                            startAdornment: (values.annualContributions.employer.type === "fixed" && <InputAdornment position="start">£</InputAdornment>)
                                        }}
                                    />
                                </PopoverField>
                            </Grid>
                        </>)}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="makesContributionsPrimary"
                                        type="checkbox"
                                    />
                                }
                                label="Are you currently contributing to a pension?"
                            />
                        </Grid>

                        {makesContributionsPrimary && (
                            <>
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employeeContributionType"
                                        popoverContent="Select the type of employee contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributions.employee.type"
                                            component={Select}
                                            label="Employee Contribution Type"
                                        >
                                            <MenuItem value="percentage">Percentage of Salary</MenuItem>
                                            <MenuItem value="fixed">Fixed Amount</MenuItem>
                                        </Field>
                                    </PopoverField>
                                </Grid>
                                {values.annualContributions.employee.type === "percentage" && (
                                    <Grid item xs={12}>
                                        <PopoverField
                                            label="employeeContributionPercentage"
                                            popoverContent="Enter the percentage of employee contribution."
                                        >
                                            <Field
                                                fullWidth
                                                name="annualContributions.employee.percentage"
                                                component={TextField}
                                                label="Employee Contribution %"
                                                type="number"
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                }}
                                            />
                                        </PopoverField>
                                    </Grid>
                                )}


                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employeeContributionValue"
                                        popoverContent="Enter the value of employee contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributions.employee.cashContribution"
                                            disabled={values.annualContributions.employee.type === "percentage"}
                                            component={TextField}
                                            label="Employee Contribution Amount"
                                            type="number"
                                            InputProps={{
                                                startAdornment: (values.annualContributions.employee.type === "fixed" && <InputAdornment position="start">£</InputAdornment>)
                                            }}
                                        />
                                    </PopoverField>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="fullNIContributionPrimary"
                                        type="checkbox"
                                    />
                                }
                                label="Will you have contributed National Insurance for 35 years at retirement?"
                            />
                        </Grid>
                        {!fullNIContributionPrimary && (

                            <>
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="niContributionYearsPrimary"
                                        popoverContent="Enter the total years of National Insurance contributions at retirement."
                                    >
                                        <Field
                                            fullWidth
                                            name="niContributionYearsPrimary"
                                            component={TextField}
                                            label="Total years of National Insurance contributions at retirement"
                                            type="number"
                                        />
                                    </PopoverField>
                                </Grid>
                                < Grid item xs={12}>
                                    {/* Add a note: "Unsure – verify your recorded contributions here - https://www.gov.uk/check-national-insurance-record" */}
                                    <Typography>{"Unsure? Verify your recorded contributions here: "}
                                        <Link target="_blank" href="https://www.gov.uk/check-national-insurance-record">https://www.gov.uk/check-national-insurance-record</Link>
                                        {" (opens in a new tab/window)"}

                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </>
                )
                }</>}
            /* =================== JSX for Partner =================== */

            secondary={<>
                <PopoverField
                    label="hasPensionsPartner"
                    popoverContent="Please toggle this switch if your Partner has any pensions as the primary account holder."
                >
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                name="hasPensionsPartner"
                                type="checkbox"
                            />
                        }
                        label="My Partner has pensions"
                    />
                </PopoverField>

                {hasPensionsPartner && (
                    <>
                        <Grid item xs={12}>
                            <PopoverField
                                label="currentPensionValuePartner"
                                popoverContent="Enter the current value of your Partner's pension."
                            >
                                <CurrencyField
                                    fullWidth
                                    name="currentPensionValuePartner"
                                    component={TextField}
                                    label="Current Combined Pension Value (all pensions)"
                                    type="number"
                                />
                            </PopoverField>
                        </Grid>
                        <Grid item xs={12}>
                            <PopoverField
                                label="currentPensionProviderDetailsPartner"
                                popoverContent="Enter your Partner's pension provider details."
                            >
                                <Field
                                    fullWidth
                                    name="currentPensionProviderDetailsPartner"
                                    component={TextField}
                                    label="Current Pension Provider Name"
                                />
                            </PopoverField>
                        </Grid>
                        {/* partnerKnowsPensionGrowthRate: true,
    partnerKnowsPensionFees: true, */}
                        <Grid item xs={12}>
                            <PopoverField
                                label="partnerKnowsPensionGrowthRate"
                                popoverContent="Please toggle this switch if you know the growth rate of your Partner's pension. Else, an average will be used."
                            >
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            name="partnerKnowsPensionGrowthRate"
                                            type="checkbox"
                                        />
                                    }
                                    label="I know the growth rate of my Partner's pension"
                                />
                            </PopoverField>
                        </Grid>
                        {values.partnerKnowsPensionGrowthRate && (<Grid item xs={12}>
                            <PopoverField
                                label="annualGrowthRatePartner"
                                popoverContent="Enter the expected annual growth rate of your Partner's pension."
                            >
                                <Field
                                    fullWidth
                                    name="annualGrowthRatePartner"
                                    component={TextField}
                                    label="Annual Growth Rate (%)"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                    }}
                                />
                            </PopoverField>
                        </Grid>)}
                        <Grid item xs={12}>
                            <PopoverField
                                label="partnerKnowsPensionFees"
                                popoverContent="Please toggle this switch if you know the annual fees of your Partner's pension. Else, an average will be used."
                            >
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            name="partnerKnowsPensionFees"
                                            type="checkbox"
                                        />
                                    }
                                    label="I know my Partner's pension's annual fees."
                                />
                            </PopoverField>
                        </Grid>
                        {values.partnerKnowsPensionFees && (<Grid item xs={12}>
                            <PopoverField
                                label="annualFeesPartner"
                                popoverContent="Enter the annual fees associated with your pension."
                            >
                                <Field
                                    fullWidth
                                    name="annualFeesPartner"
                                    component={TextField}
                                    label="Annual Fees (%)"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                                    }}
                                />
                            </PopoverField>
                        </Grid>)}
                        <Grid item xs={12}>
                            <PopoverField
                                label="employerMakesContributionsPartner"
                                popoverContent="Please toggle this switch if your Partner's employer is currently contributing to a pension."
                            >
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            name="employerMakesContributionsPartner"
                                            type="checkbox"
                                        />
                                    }
                                    label="Is your Partner's employer currently contributing to a pension?"
                                />
                            </PopoverField>
                        </Grid>
                        {employerMakesContributionsPartner && (<>

                            <Grid item xs={12}>
                                <PopoverField
                                    label="employerContributionTypePartner"
                                    popoverContent="Select the type of employer contribution."
                                >
                                    <Field
                                        fullWidth
                                        name="annualContributionsPartner.employer.type"
                                        component={Select}
                                        label="Employer Contribution Type"
                                    >
                                        <MenuItem value="percentage">Percentage of Salary</MenuItem>
                                        <MenuItem value="fixed">Fixed Amount</MenuItem>
                                    </Field>
                                </PopoverField>
                            </Grid>


                            {values.annualContributionsPartner.employer.type === "percentage" && (
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employerContributionPercentagePartner"
                                        popoverContent="Enter the percentage of employer contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributionsPartner.employer.percentage"
                                            component={TextField}
                                            label="Employer Contribution %"
                                            type="number"
                                            InputProps={{
                                                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                            }}
                                        />
                                    </PopoverField>
                                </Grid>)}
                            <Grid item xs={12}>
                                <PopoverField
                                    label="employerContributionValuePartner"
                                    popoverContent="Enter the value of employer contribution."
                                >
                                    <Field
                                        fullWidth
                                        name="annualContributionsPartner.employer.cashContribution"
                                        component={TextField}
                                        label="Employer Contribution Amount"
                                        type="number"
                                        disabled={values.annualContributionsPartner.employer.type === "percentage"}
                                        InputProps={{
                                            endAdornment: (values.annualContributionsPartner.employer.type === "percentage" && <InputAdornment position="end">%</InputAdornment>),
                                            startAdornment: (values.annualContributionsPartner.employer.type === "fixed" && <InputAdornment position="start">£</InputAdornment>)
                                        }}
                                    />
                                </PopoverField>
                            </Grid>
                        </>)}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="makesContributionsPartner"
                                        type="checkbox"
                                    />
                                }
                                label="Are you currently contributing to a pension?"
                            />
                        </Grid>
                        {makesContributionsPartner && (
                            <>
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employeeContributionType"
                                        popoverContent="Select the type of employee contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributionsPartner.employee.type"
                                            component={Select}
                                            label="Employee Contribution Type"
                                        >
                                            <MenuItem value="percentage">Percentage of Salary</MenuItem>
                                            <MenuItem value="fixed">Fixed Amount</MenuItem>
                                        </Field>
                                    </PopoverField>
                                </Grid>
                                {values.annualContributionsPartner.employee.type === "percentage" && (
                                    <Grid item xs={12}>
                                        <PopoverField
                                            label="employeeContributionPercentagePartner"
                                            popoverContent="Enter the percentage of employee contribution."
                                        >
                                            <Field
                                                fullWidth
                                                name="annualContributionsPartner.employee.percentage"
                                                component={TextField}
                                                label="Employee Contribution %"
                                                type="number"
                                                InputProps={{
                                                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                }}
                                            />
                                        </PopoverField>
                                    </Grid>
                                )}


                                <Grid item xs={12}>
                                    <PopoverField
                                        label="employeeContributionValue"
                                        popoverContent="Enter the value of employee contribution."
                                    >
                                        <Field
                                            fullWidth
                                            name="annualContributionsPartner.employee.cashContribution"
                                            disabled={values.annualContributionsPartner.employee.type === "percentage"}
                                            component={TextField}
                                            label="Employee Contribution Amount"
                                            type="number"
                                            InputProps={{
                                                startAdornment: (values.annualContributionsPartner.employee.type === "fixed" && <InputAdornment position="start">£</InputAdornment>)
                                            }}
                                        />
                                    </PopoverField>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Field
                                        component={Switch}
                                        name="fullNIContributionPartner"
                                        type="checkbox"
                                    />
                                }
                                label="Will your Partner have contributed National Insurance for 35 years at retirement?"
                            />
                        </Grid>
                        {!fullNIContributionPartner && (

                            <>
                                <Grid item xs={12}>
                                    <PopoverField
                                        label="niContributionYearsPartner"
                                        popoverContent="Enter the total years of National Insurance contributions at retirement."
                                    >
                                        <Field
                                            fullWidth
                                            name="niContributionYearsPartner"
                                            component={TextField}
                                            label="Total years of National Insurance contributions at retirement"
                                            type="number"
                                        />
                                    </PopoverField>
                                </Grid>
                                < Grid item xs={12}>
                                    {/* Add a note: "Unsure – verify your recorded contributions here - https://www.gov.uk/check-national-insurance-record" */}
                                    <Typography>{"Unsure? Verify your recorded contributions here: "}
                                        <Link target="_blank" href="https://www.gov.uk/check-national-insurance-record">https://www.gov.uk/check-national-insurance-record</Link>
                                        {" (opens in a new tab/window)"}

                                    </Typography>
                                </Grid>
                            </>
                        )}

                    </>
                )}

            </>}
        ></FormStepContainer >)
};

export default PersonalPensionStep;

export const validationSchema = Yup.object().shape({
    /* =================== Schema Validation for Primary =================== */
    hasPensionsPrimary: Yup.boolean().required('Please select if you have pensions'),
    currentPensionValuePrimary: Yup.number().min(0).when('hasPensionsPrimary', {
        is: true,
        then: Yup.number().required('Current Pension Value is required'),
        otherwise: Yup.number().notRequired()
    }),
    currentPensionProviderDetailsPrimary: Yup.string().when('hasPensionsPrimary', {
        is: true,
        then: Yup.string().required('Current Pension Provider Details is required'),
        otherwise: Yup.string().notRequired()
    }),
    primaryKnowsPensionGrowthRate: Yup.boolean().when('hasPensionsPrimary', {
        is: true,
        then: Yup.boolean().required('Please select if you know the growth rate of your pension'),
        otherwise: Yup.boolean().notRequired()
    }),
    annualGrowthRate: Yup.number().min(0).max(100).when('hasPensionsPrimary', {
        is: true,
        then: Yup.number().when('primaryKnowsPensionGrowthRate', {
            is: true,
            then: Yup.number().required('Annual Growth Rate is required'),
            otherwise: Yup.number().notRequired()
        })
    }),
    primaryKnowsPensionFees: Yup.boolean().when('hasPensionsPrimary', {
        is: true,
        then: Yup.boolean().required('Please select if you know the annual fees of your pension'),
        otherwise: Yup.boolean().notRequired()
    }),
    annualFees: Yup.number().min(0).max(100).when('hasPensionsPrimary', {
        is: true,
        then: Yup.number().when('primaryKnowsPensionFees', {
            is: true,
            then: Yup.number().required('Annual Fees is required'),
            otherwise: Yup.number().notRequired()
        })
    }),
    employerMakesContributionsPrimary: Yup.boolean().when('has PensionsPrimary', {
        is: true,
        then: Yup.boolean().required('Employer contributions is required'),
        otherwise: Yup.boolean().notRequired()
    }),
    annualContributions: Yup.object({
        employer: Yup.object({
            type: Yup.string().required('Employer Contribution Type is required'),
            percentage: Yup.number().min(0).max(100).required('Employer Contribution Percentage is required'),
            cashContribution: Yup.number().min(0).required('Employer Contribution Value is required')
        }),
        employee: Yup.object({
            type: Yup.string().required('Employer Contribution Type is required'),
            percentage: Yup.number().min(0).max(100).required('Employer Contribution Percentage is required'),
            cashContribution: Yup.number().min(0).required('Employer Contribution Value is required')
        })
    }),
    fullNIContributionPrimary: Yup.boolean().when('hasPensionsPrimary', {
        is: true,
        then: Yup.boolean().required('Full NI Contribution is required'),
        otherwise: Yup.boolean().notRequired()
    }),
    niContributionYearsPrimary: Yup.number().when('fullNIContributionPrimary', {
        is: false,
        then: Yup.number().min(0).max(100).required('NI Contribution Years is required'),
        otherwise: Yup.number().notRequired()
    }),
    /* =================== Schema Validation for Partner =================== */
    hasPensionsPartner: Yup.boolean().required('Please select if you have pensions'),
    currentPensionValuePartner: Yup.number().min(0).when('hasPensionsPartner', {
        is: true,
        then: Yup.number().required('Current Pension Value is required'),
        otherwise: Yup.number().notRequired()
    }),
    currentPensionProviderDetailsPartner: Yup.string().when('hasPensionsPartner', {
        is: true,
        then: Yup.string().required('Current Pension Provider Details is required'),
        otherwise: Yup.string().notRequired()
    }),
    partnerKnowsPensionGrowthRate: Yup.boolean().when('hasPensionsPartner', {
        is: true,
        then: Yup.boolean().required('Please select if you know the growth rate of your pension'),
        otherwise: Yup.boolean().notRequired()
    }),
    annualGrowthRatePartner: Yup.number().min(0).max(100).when('hasPensionsPartner', {
        is: true,
        then: Yup.number().when('partnerKnowsPensionGrowthRate', {
            is: true,
            then: Yup.number().required('Annual Growth Rate is required'),
            otherwise: Yup.number().notRequired()
        })
    }),
    partnerKnowsPensionFees: Yup.boolean().when('hasPensionsPartner', {
        is: true,
        then: Yup.boolean().required('Please select if you know the annual fees of your pension'),
        otherwise: Yup.boolean().notRequired()
    }),
    annualFeesPartner: Yup.number().min(0).max(100).when('hasPensionsPartner', {
        is: true,
        then: Yup.number().when('partnerKnowsPensionFees', {
            is: true,
            then: Yup.number().required('Annual Fees is required'),
            otherwise: Yup.number().notRequired()
        })
    }),
    employerMakesContributionsPartner: Yup.boolean().when('hasPensionsPartner', {
        is: true,
        then: Yup.boolean().required('Employer contributions is required'),
        otherwise: Yup.boolean().notRequired()
    }),
    annualContributionsPartner: Yup.object({
        employer: Yup.object({
            type: Yup.string().required('Employer Contribution Type is required'),
            percentage: Yup.number().min(0).max(100).required('Employer Contribution Percentage is required'),
            cashContribution: Yup.number().min(0).required('Employer Contribution Value is required')
        }),
        employee: Yup.object({
            type: Yup.string().required('Employer Contribution Type is required'),
            percentage: Yup.number().min(0).max(100).required('Employer Contribution Percentage is required'),
            cashContribution: Yup.number().min(0).required('Employer Contribution Value is required')
        })
    }),
    fullNIContributionPartner: Yup.boolean().when('hasPensionsPartner', {
        is: true,
        then: Yup.boolean().required('Full NI Contribution is required'),
        otherwise: Yup.boolean().notRequired()
    }),
    niContributionYearsPartner: Yup.number().when('fullNIContributionPartner', {
        is: false,
        then: Yup.number().min(0).max(100).required('NI Contribution Years is required'),
        otherwise: Yup.number().notRequired()
    }),

})