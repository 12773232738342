import React, { useState, useEffect } from "react";
import { CircularProgress, Button, Stack, Step, StepLabel, Stepper, Typography, Grid, Box, Avatar } from "@mui/material";
import { useFormikContext } from "formik";


export const Nav = ({ step, stepIndex, steps, onSubmit, setStep }) => {
  const isLastStep = stepIndex === steps.length - 1;
  const buttonText = step.props.buttonText
  // Removing this until it is needed
  // const noop = step.props.noop

  // Grab what we need from formik without prop-drilling
  const {
    validateForm,
    handleSubmit,
    isSubmitting,
    isValid,
    // dirty,
  } = useFormikContext();


  // Will run form.validateForm() when the currentIndex prop is changed
  useEffect(() => {
    validateForm();
  }, [stepIndex, validateForm]);

  return (
    <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
      {stepIndex > 0 && (
        <Button
          variant="outlined"
          onClick={() => setStep((s) => s - 1)}
        >
          Back
        </Button>
      )}
      {!isLastStep && (
        <Button
          // disabled={(!isValid || !dirty)}
          disabled={!isValid}
          variant="contained"
          onClick={() => setStep((s) => s + 1)}
        >
          {isSubmitting ? <CircularProgress size={14} /> : buttonText || "Next"}
        </Button>
      )}
      {isLastStep && (
        // <Button variant="contained" type="submit" disabled={!isValid || !dirty || isSubmitting}>
        <Button variant="contained" type="submit" disabled={!isValid || isSubmitting}>
          {buttonText || "Submit"}
        </Button>
      )}
    </Stack>

  );
};